<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <indexHeader
          :title="$t('accountsWithdrawals.accountWithdrawals')"
          :description="$t('accountsWithdrawals.fromHereYouCanControlAccountWithdrawals')"
        />
        <indexTable
          :searchText="$t('accountsWithdrawals.SearchForAnOperation')"
          :emptyTableText="$t('accountsWithdrawals.thereAreNoWithdrawalsOperations')"
          :buttonRole="$user.role.accounts_withdrawals_add"
          :emptyTableSubText="
            $t('allerts.TryAddingSomeItemsToTheTableAndTryAgain')
          "
          :statusSearch="true"
          :statusValues="[
            { title: $t('orders.situation'), value: ''},
            { title: $t('orders.all'), value: ''},
            { title: $t('invoices.draft'), value: 0 },
            { title: $t('invoices.approved'), value: 1 },
          ]"
          :cloumns="[
            { column: 'code', title: $t('accountsWithdrawals.Process'), type: 'mainLink', sort: true },
            {
              column: 'account_id',
              title: $t('accountsWithdrawals.accountName'),
              type: 'link',
              to: 'account',
              sort: true,
              link: true,
            },
            {
              column: 'safe_id',
              title: $t('accountsWithdrawals.Treasury'),
              type: 'link',
              to: 'safe',
              sort: true,
              link: true,
            },
            { column: 'date', title: $t('accountsWithdrawals.date'), type: 'text', sort: true },
            {
              column: 'description',
              title: $t('accountsWithdrawals.AndThatIsAbout'),
              type: 'text',
              sort: true,
            },
            {
              column: 'cost',
              title: $t('accountsWithdrawals.cost'),
              type: 'text',
              sort: true
            },
            {column: 'status', title: $t('bills.situation') ,type: 'status' , sort: true ,  values: [
                  {title: $t('invoices.draft'), value: 0, color: 'dark'},
                  {title: $t('invoices.approved'), value: 1, color: 'success'},
                ]
            },
            {
              column: 'options',
              title: $t('accountsWithdrawals.actions'),
              type: 'options',
              options: [
                { name: 'show' },
                {name: 'printAccountsWithdrawal',   role: true},
                {
                  name: 'edit',
                  role:
                    $user.admin ||
                    $user.role.accounts_withdrawals_edit,
                },
                {name: 'file'},
                { name: 'printPurchasePayment', role: true },
                { name: 'download', role: true },
                {
                  name: 'delete',
                  role:
                    $user.admin ||
                    $user.role.accounts_withdrawals_delete,
                },
              ],
            },
          ]"
          :deleteText="{
            attention: $t('allerts.Attention'),
            areYouReallySureToDeleteTheItem:
              $t('allerts.areYouReallySureToDeleteTheItem'),
            close: $t('allerts.close'),
            confirm: $t('allerts.confirm'),
            loading: $t('allerts.loading'),
          }"
        />
      </div>
    </div>
  </div>
</template>
<script>

import indexHeader from "../elements/index/indexHeader.vue";
import indexTable from "../elements/index/indexTable.vue";
export default {
  data() {
    return {
      path: "/accountsWithdrawals",
    };
  },
  components: {
    indexHeader,
    indexTable,
  },
};
</script>
